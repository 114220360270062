<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t("certificate.management") }}</li>
            <li>{{ $t("certificate.add") }}</li>
          </ul>
        </nav>
      </div>

      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div uk-grid="" class="uk-grid">
        <div class="uk-width-1-4@m uk-first-column">
          <!--uk-flex-last@m-->
          <nav class="responsive-tab style-3 setting-menu card uk-sticky"
            uk-sticky="top:30 ; offset:100; media:@m ;bottom:true; animation: uk-animation-slide-top">
            <ul class="uk-tab-left uk-tab"
              uk-switcher="connect: #user-group-tab ; animation: uk-animation-slide-left-medium, uk-animation-slide-right-medium">
              <li class="uk-active">
                <a href="#" aria-expanded="true"><i class="uil-layer-group"></i>
                  {{ $t("certificate.feature") }}</a>
              </li>
              <li>
                <a href="#" aria-expanded="false"><i class="uil-swatchbook"></i> Şablonlar</a>
              </li>
            </ul>
          </nav>
          <div class="uk-sticky-placeholder" hidden="" style="height: 158px; margin: 0px 0px 20px"></div>
        </div>

        <div class="uk-width-expand">
          <ul class="uk-switcher uk-margin" id="user-group-tab" style="touch-action: pan-y pinch-zoom">
            <!--Özellikler-->
            <li class="uk-active">
              <div class="card rounded">
                <div class="p-3 d-flex justify-content-between">
                  <h4 class="mb-0">
                    {{ $t("certificate.certificate_feature") }}
                  </h4>
                </div>
                <hr class="m-0" />
                <div class="course-card-body">
                  <form class="regular-form" action="">
                    <div class="
                                    uk-grid-collapse
                                    uk-child-width-1-1@s
                                    uk-grid
                                    uk-grid-stack
                                  " uk-grid="">
                      <div class="
                                      uk-padding-small
                                      position-relative
                                      uk-first-column
                                    ">
                        <div class="form-group row">
                          <div class="col-md-12">
                            <label class="col-form-label" for="name">{{ $t("certificate.name")
                            }}<span class="required">*</span></label>
                            <input type="text" class="form-control" v-model="name"
                              :placeholder="$t('certificate.name')" />
                          </div>
                        </div>
                        <div class="form-group row mt-3 input-checkbox">
                          <div class="col-md-12">
                            <div class="d-flex">
                              <input type="checkbox" id="status_id" v-model="status_id" />
                              <label class="ml-2" for="status_id">{{
                                status_id
                                ? $t("general.active")
                                : $t("general.passive")
                              }}</label>
                            </div>
                          </div>
                        </div>

                        <hr />
                        <div class="form-group row">
                          <div :class="expireable == 1 ? 'col-md-6' : 'col-md-12'">
                            <label class="col-form-label" for="name">{{ $t("certificate.validity_period")
                            }}<span class="required">*</span></label>
                            <select class="form-control" v-model="expireable">
                              <option value="0">{{
                                $t("certificate.indefinite")
                              }}</option>
                              <option value="1" selected="">{{
                                $t("certificate.timed")
                              }}</option>
                            </select>
                          </div>
                          <div v-if="expireable == '1'" class="col-md-6">
                            <label class="col-form-label" for="name">{{
                              $t("general.end_date")
                            }}</label>
                            <input type="date" class="form-control" v-model="end_date" required="" />
                          </div>
                        </div>
                        <hr />
                        <div class="form-group row">
                          <div class="col-md-12">
                            <label class="col-form-label" for="renewal_period">{{ $t("certificate.renewal_period")
                            }}<span class="required">*</span></label>
                            <select v-model="renewal_type_id" id="renewal_period">
                              <option value="1">{{
                                $t("certificate.automatic")
                              }}</option>
                            </select>
                          </div>
                        </div>
                        <hr />
                        <div class="form-group row">
                          <div class="col-md-12">
                            <label class="col-form-label">{{ $t("certificate.text")
                            }}<span class="required">*</span></label>
                            <textarea type="text" class="form-control" v-model="text"
                              :placeholder="$t('certificate.body_text_example')" required="" />
                          </div>
                        </div>
                        <hr />
                        <div class="form-group row">
                          <div class="col-md-12">
                            <label class="col-form-label">{{ $t("certificate.certifying_name")
                            }}<span class="required">*</span></label>
                            <input type="text" class="form-control" v-model="certifying_name"
                              :placeholder="$t('certificate.certifying_name')" required="" />
                            <label class="col-form-label">{{ $t("certificate.certifying_title")
                            }}<span class="required">*</span></label>
                            <input type="text" class="form-control" v-model="certifying_title"
                              :placeholder="$t('certificate.certifying_title')" required="" />
                          </div>
                        </div>
                        <hr />
                        <div class="form-group row mb-2">
                          <div class="col-md-12">
                            <div class="d-flex align-items-center">
                              <label class="col-form-label" for="mail_notify">
                                {{ $t("certificate.mail_notify") }}</label>
                              <input type="checkbox" class="ml-2" id="mail_notify" v-model="mail_notify" />
                            </div>
                          </div>
                        </div>
                        <div class="form-group row mb-2">
                          <div class="col-md-12">
                            <div class="d-flex align-items-center">
                              <label class="col-form-label" for="shareable">
                                {{ $t("certificate.share_on_social") }}</label>
                              <input type="checkbox" class="ml-2" id="shareable" v-model="shareable" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </li>
            <!--Şablonalr-->
            <li>
              <div class="card rounded">
                <div class="p-3 d-flex justify-content-between align-items-center">
                  <h4 class="mb-0">{{ $t("general.cetificate_template") }}</h4>
                </div>

                <hr class="m-0" />
                <div class="course-card-body">
                  <div class="uk-padding-small position-relative">
                    <div class="form-group row mb-2">
                      <div class="col-md-12">
                        <label class="col-form-label" for="name">{{
                          $t("general.template_choose")
                        }}</label>
                        <select class="selectpicker2 mb-3" v-model="certificate_template_id" @change="changeCoverPicture">
                          <option v-if="item" data-icon="uil-swatchbook" v-for="item in templates" :value="item.id"
                            :selected="item.id == certificate_template_id">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="certificate-container position-relative">
                      <div class="certificate template2">
                        <div class="water-mark-overlay"></div>
                        <div class="certificate-header">
                          <img :src="company.logo_link" class="logo" alt="" />
                        </div>
                        <div class="certificate-body">
                          <p class="certificate-title">
                            <strong>{egitim.adi}</strong>
                          </p>
                          <h1>{{ name }}</h1>
                          <p class="student-name">
                            <span>Sayın</span>
                            {k.adi} {k.soyadi}
                          </p>
                          <div class="certificate-content">
                            <div class="about-certificate">
                              <p>
                                {{ text }}
                              </p>
                            </div>
                          </div>
                          <div class="certificate-footer">
                            <div class="row">
                              <div class="col-md-12 text-center">
                                <p>{{ certifying_name }}</p>
                                <span>{{ certifying_title }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <img v-if="cover_image_link" :src="cover_image_link" class="certificate-background-img" alt="" />
                    </div>
                  </div>
                </div>

                <div class="card-footer p-3" style="text-align: end ">
                  <button class="uk-button uk-button-primary small" type="button" @click="saveForm">
                    <i class="icon-feather-arrow-right"></i>
                    {{ $t("general.save") }}
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import moment from "moment";
import module, {
  BASE_URL,
  CREATE_ITEM,
  MODULE_NAME,
  ERROR,
} from "@/core/services/store/certificate.module";

import template_module, {
  BASE_URL as TEMPLATE_BASE_URL,
  GET_ITEMS as TEMPLATE_GET_ITEMS,
  ITEMS as TEMPLATE_ITEMS,
  MODULE_NAME as TEMPLATE_MODULE_NAME,
} from "@/core/services/store/certificate_template.module";

import company_module, {
  BASE_URL as COMPANY_BASE_URL,
  GET_ITEM as COMPANY_GET_ITEMS,
  ITEM as COMPANY_ITEM,
  MODULE_NAME as COMPANY_MODULE_NAME,
} from "@/core/services/store/company.module";
export default {
  name: "CertificateAdd",
  components: {},
  data() {
    return {
      certificate_template_id: 1,
      name: null,
      certifying_name: "",
      certifying_title: null,
      cover_image_link: null,
      text: this.$t("certificate.body_text_example"),
      expireable: "0",
      end_date: moment().format("YYYY-MM-DD"),
      renewal_type_id: "1",
      shareable: false,
      mail_notify: false,
      status_id: true,
      successMessage: null,
      errorMessages: [],
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
    registerStoreModule(TEMPLATE_MODULE_NAME, template_module);
    registerStoreModule(COMPANY_MODULE_NAME, company_module);
  },
  computed: {
    templates: {
      get() {
        return store.getters[TEMPLATE_MODULE_NAME + "/" + TEMPLATE_ITEMS];
      },
      set(value) { },
    },
    company: {
      get() {
        return store.getters[COMPANY_MODULE_NAME + "/" + COMPANY_ITEM];
      },
      set(value) { },
    },
    updateCoverLink() {
      if (this.templates[0]) {
        let cover = this.templates[0].cover_image_link;
        this.cover_image_link = cover;
      }
      return this.cover_image_link;
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    showCertificate() { },
    getTemplateItems() {
      this.$store.dispatch(TEMPLATE_MODULE_NAME + "/" + TEMPLATE_GET_ITEMS, {
        url: TEMPLATE_BASE_URL,
        filters: {
          order: "asc",
          sort: "id",
          status_id: 1,
        },
      });
    },
    getCompanyItem() {
      this.$store.dispatch(COMPANY_MODULE_NAME + "/" + COMPANY_GET_ITEMS, {
        url: COMPANY_BASE_URL + "/self",
      });
    },
    changeCoverPicture() {
      if (this.certificate_template_id) {
        let pos = this.templates
          .map(function (x) {
            return x.id;
          })
          .indexOf(this.certificate_template_id);
        this.cover_image_link = this.templates[pos]["cover_image_link"];
      }
    },
    saveForm() {
      let self = this;
      let formData = new FormData();
      this.errorMessages = [];
      if (self.name && self.name.length < 2) {
        this.scrollToTop();
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("certificate.name")
          )
        );
        return false;
      }
      if (self.certifying_name.length < 2) {
        this.scrollToTop();
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("certificate.certifying_name")
          )
        );
        return false;
      }
      if (self.certifying_title.length < 2) {
        this.scrollToTop();
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("certificate.certifying_title")
          )
        );
        return false;
      }
      if (self.text < 2) {
        this.scrollToTop();
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("certificate.text")
          )
        );
        return false;
      }
      if (!self.text.includes("{egitim.adi}")) {
        this.scrollToTop();
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("certificate.text") + " {egitim.adi}"
          )
        );
        return false;
      }
      if (!self.text.includes("{tamamlanma.tarihi}")) {
        this.scrollToTop();
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("certificate.text") + " {tamamlanma.tarihi}"
          )
        );
        return false;
      }
      if (
        self.expireable == true &&
        !moment(self.end_date).isSameOrAfter(moment().format("YYYY-MM-DD"))
      ) {
        this.scrollToTop();
        this.errorMessages.push(
          this.$t("validation.required_field").replace(
            ":NAME",
            this.$t("general.end_date")
          )
        );
        return false;
      }
      formData.append("name", self.name);
      formData.append("certificate_template_id", self.certificate_template_id);
      formData.append("certifying_name", self.certifying_name);
      formData.append("certifying_title", self.certifying_title);
      formData.append("text", self.text);
      formData.append("end_date", self.end_date);
      formData.append("renewal_type_id", self.renewal_type_id);
      formData.append("expireable", self.expireable == true ? "1" : "0");
      formData.append("shareable", self.shareable == true ? "1" : "0");
      formData.append("mail_notify", self.mail_notify == true ? "1" : "0");
      formData.append("status_id", self.status_id == true ? "1" : "0");
      store
        .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
          url: BASE_URL,
          contents: formData,
        })
        .then(() => {
          self.scrollToTop();
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_created");
            this.$router.push({ name: "company.certificate.list" });
          } else this.errorMessages.push(this.error);
        });
    },
    resetMessages() {
      this.errorMessages = [];
      this.successMessage = null;
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.getTemplateItems();
    this.getCompanyItem();
  },
  watch: {
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
    templates: function (val) {
      setTimeout(() => {
        $(".selectpicker2").selectpicker();
      }, 200);
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.certificate-background-img {
  top: 0px;
  height: 100%;
  width: 100%;
  left: 0px;
  bottom: 0px;
  right: 0px;
  position: absolute;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 1;
}

.certificate.template2::after {
  background-image: unset !important;
  background-color: transparent !important;
}

.certificate.template2 {
  z-index: 101;
  position: relative;
}
</style>
